import React from "react";
import PropTypes from "prop-types";

import asyncComponent from "../AsyncComponent";
import Headline from "../Article/Headline";
import Bodytext from "../Article/Bodytext";
import Meta from "./Meta";
import Author from "./Author";
import NextPrev from "./NextPrev";

const Share = asyncComponent(() =>
  import("./Share")
    .then(module => {
      return module.default;
    })
    .catch(error => { })
);

const Post = props => {
  const {
    post,
    post: {
      // prefix = "111",
      // category = "testimonies",
      tags: category,
      data: {
        author_link: { document: doc },
        title: { text: title },
        content: { html: html }
      },
      first_publication_date
    },
    next: nextPost,
    prev: prevPost,
    theme
  } = props;

  const authornote = doc[0].data.author_name.html;
  const author = doc[0].data.author_name.text;

  return (
    <React.Fragment>
      <header>
        <Headline title={title} theme={theme} />
        <Meta
          publishDate={first_publication_date}
          author={author}
          category={category}
          theme={theme}
        />
      </header>
      <Bodytext html={html} theme={theme} />
      <footer>
        <Share post={post} theme={theme} />
        <Author note={authornote} theme={theme} />
        <NextPrev next={nextPost} prev={prevPost} theme={theme} />
        {/* <Comments slug={slugs[0]} facebook={facebook} theme={theme} /> */}
      </footer>
    </React.Fragment>
  );
};

Post.propTypes = {
  post: PropTypes.object.isRequired,
  // authornote: PropTypes.string.isRequired,
  next: PropTypes.object,
  prev: PropTypes.object,
  theme: PropTypes.object.isRequired
};

export default Post;
