import React from "react";
import PropTypes from "prop-types";

import { FaCalendar, FaTag } from "react-icons/fa/";
import { FaUser } from "react-icons/fa/";
import Moment from "react-moment";
import { Link } from "gatsby";

const Meta = props => {
  const { publishDate, author: authorName, category, theme } = props;

  return (
    <p className="meta">
      <span>
        <FaCalendar size={18} /> <Moment format="MM/DD/YYYY HH:MM" date={publishDate} />
      </span>
      <span>
        <FaUser size={18} />
        <Link to={`/author/${authorName.replace(/\s/g, "-").toLowerCase()}`}>{authorName}</Link>
      </span>
      {category && category.length ? (
        <span>
          <FaTag size={18} />
          {category.slice(0, 5).map((item, i) => (
            <Link
              className="category"
              key={i}
              to={`/tag/${item.replace(/\s/g, "-").toLowerCase()}`}
            >
              {item}
            </Link>
          ))}
        </span>
      ) : null}

      {/* --- STYLES --- */}
      <style jsx>{`
        .meta {
          display: flex;
          flex-flow: row wrap;
          font-size: 0.8em;
          margin: ${theme.space.m} 0;
          background: transparent;

          :global(svg) {
            fill: ${theme.icon.color};
            margin: ${theme.space.inline.xs};
          }
          span {
            align-items: center;
            display: flex;
            text-transform: uppercase;
            margin: ${theme.space.xs} ${theme.space.s} ${theme.space.xs} 0;
            :global(a) {
              margin-right: ${theme.space.xs};
            }
          }
        }
        @from-width tablet {
          .meta {
            margin: ${`calc(${theme.space.m} * 1.5) 0 ${theme.space.m}`};
          }
        }
      `}</style>
    </p>
  );
};

Meta.propTypes = {
  publishDate: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  category: PropTypes.array,
  theme: PropTypes.object.isRequired
};

export default Meta;
